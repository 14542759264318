import React from 'react';
import { usePositionStatus } from '../../hooks/use-position-status';
import { PositionMenu } from './PositionMenu';
import { ActionButton } from '../ActionsMenu/ActionButton';

const PositionBulkActions = ({ selectedPositions, handleAction }) => {
  const [disableStatusMenuItem, positionStatus] = usePositionStatus();
  
  return disableStatusMenuItem || (['Cancelled', 'Filled', 'On Hold - HM Delay', 'Offer Negotiation', 'Offer Released', 'Offer Accepted'].indexOf(positionStatus) >= 0) 
    ? null
    : <PositionMenu label="Bulk Actions" Component={ActionButton} row={selectedPositions[0]} handleAction={handleAction} type="bulk"/>
};

export default PositionBulkActions;
