import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import PublicIcon from '@mui/icons-material/Public';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit'
import AutorenewIcon from '@mui/icons-material/Autorenew';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import { usePositionDialogContent } from './usePositionDialogContent';
import { useSelector } from 'react-redux';
import { formDataApi } from '../../axios';
import { useSnackbar } from 'notistack';
import { usePositionPublicationStatus } from '../../hooks/use-position-publication-status';
import { usePositionStatus } from '../../hooks/use-position-status';
import { updateAllSelectedPositions } from '../../store/slices/positions';
import { useDispatch } from 'react-redux';

export const PositionMenu = ({Component, row, handleAction, type = "single", label }) => {
  const [actionType, setActionType] = useState();
  const { user } = useSelector((state) => state.session);
  const { selectedPositions } = useSelector((state) => state.positions);
  const [disablePublish, disableCR] = usePositionPublicationStatus();
  const [ disableStatusMenuItem, positionStatus ] = usePositionStatus();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleConfirm = async (payload) => {
    try {
      setActionType();
      const data = new FormData();
      const selectedPositionIds = type === "bulk" ? selectedPositions : [row?.id]

      if (actionType === 'map-cr-policy') {
        data.append('positionIds', selectedPositionIds.join(','));
        data.append('changeRequestPolicyId', payload.crPolicy);
        const response = await formDataApi.post('/updatePositionsMappedToChangeRequestPolicy', data);
        if (response.status === 200) {
          if(response.data?.status === 'ERROR') throw new Error(response.data?.message)
          enqueueSnackbar(response.data.message, { variant: 'success' });
          handleAction();
        }
      }else if (actionType === 'resync') {
        data.append('positionIds', selectedPositionIds.join(','));
        const response = await formDataApi.post('/resyncPosition', data);
        if (response.status === 200) {
          if(response.data?.status === 'ERROR') throw new Error(response.data?.message)
          enqueueSnackbar(response.data.message, { variant: 'success' });
          handleAction();
        }
      } else if (payload.singlePositionUpdate) {
        data.append('id', selectedPositionIds.join(','));
        delete payload.singlePositionUpdate;
        for (const key in payload) {
          data.append(key, payload[key]);
        }
        const response = await formDataApi.post('/modifyPosition', data);
        if (response.status === 200) {
          if(response.data?.status === 'ERROR') throw new Error(response.data?.message)
          enqueueSnackbar("Position status updated successfully.", { variant: 'success' });
          handleAction();
        }
      } else if(actionType === 'update-start-date'){
        data.append('positionId', selectedPositionIds.join(','));
        data.append('startDate', payload.startDate);
        const response = await formDataApi.post('/updatePositionStartDate', data);
        if (response.status === 200) {
          if(response.data?.status === 'ERROR') throw new Error(response.data?.message)
          enqueueSnackbar(response.data.message, { variant: 'success' });
          handleAction();
        }
      }else {
        data.append('positionIds', selectedPositionIds.join(','));
        for (const key in payload) {
          const statusUpdatedKey = payload[key] === 'Open' ? 'Open Pending Intake' : payload[key];
          data.append(key, statusUpdatedKey);
          if (key === 'publicationStatus' && payload[key] === 'Published') {
            data.append('status', 'Open Active Sourcing');
          }
        }
        const response = await formDataApi.post('/bulkUpdatePositionStatuses', data);
        if (response.status === 200) {
          if(response.data?.status === 'ERROR') throw new Error(response.data?.message)
          enqueueSnackbar(response.data.message, { variant: 'success' });
          handleAction();
        }
      }
    } catch (error) {
      console.log('error while updating bulk status update', error);
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      if(type === "bulk") dispatch(updateAllSelectedPositions({ isChecked: false }));
    }
  };

  const dialogContent = usePositionDialogContent({ data: row, actionType, setActionType, handleConfirm, type});

  const actionItems = type === "bulk" 
  ? [
    {
      label: 'Publish',
      type: 'Published',
      icon: <PublicIcon />,
      onClick: (action) => setActionType(action.type),
      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_PUBLISH_POSITION'],
    },
    {
      label: 'Approve',
      type: 'approve',
      icon: <CheckIcon />,
      onClick: (action) => setActionType(action.type),
      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_BULK_UPDATE_POSITION_STATUS'],
    },
    {
      label: 'Reject',
      type: 'reject',
      icon: <CloseIcon />,
      onClick: (action) => setActionType(action.type),
      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_BULK_UPDATE_POSITION_STATUS'],
    },
    {
      label: 'Map Change Policy',
      type: 'map-cr-policy',
      icon: <InsertDriveFileIcon />,
      onClick: (action) => setActionType(action.type),
      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_MAP_POSITION_TO_CHANGE_REQUEST_POLICY'],
    },
    {
      label: 'Resync',
      type: 'resync',
      icon: <AutorenewIcon />,
      onClick: (action) => setActionType(action.type),
      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_RESYNC_POSITION'],
    },
  ]
  : [
    {
      label: 'Publish',
      type: 'published',
      icon: <PublicIcon />,
      onClick: (action) => setActionType(action.type),
      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_PUBLISH_POSITION'],
    },
    {
      label: 'Approve',
      type: 'approve',
      icon: <CheckIcon />,
      onClick: (action) => setActionType(action.type),
      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_UPDATE_SINGLE_POSITION_STATUS'],
    },
    {
      label: 'Modify',
      type: 'modify',
      icon: <EditIcon />,
      onClick: () => navigate(`/positions/modify/${row.id}`),
      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_UPDATE_POSITION'],
    },
    {
      label: 'Reject',
      type: 'reject',
      icon: <CloseIcon />,
      onClick: (action) => setActionType(action.type),
      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_UPDATE_SINGLE_POSITION_STATUS'],
    },
    {
      label: 'Map Change Policy',
      type: 'map-cr-policy',
      icon: <InsertDriveFileIcon />,
      onClick: (action) => setActionType(action.type),
      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_MAP_POSITION_TO_CHANGE_REQUEST_POLICY'],
    },
    {
      label: 'Resync',
      type: 'resync',
      icon: <AutorenewIcon />,
      onClick: (action) => setActionType(action.type),
      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_RESYNC_POSITION'],
    },
    {
      label: 'Create Change Request',
      type: 'create-cr',
      icon: <ChangeCircleIcon />,
      onClick: (action) => setActionType(action.type),
      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_CREATE_CHANGE_REQUEST'],
    },
    {
      label: 'Update Position Start Date',
      type: 'update-start-date',
      icon: <EditCalendarIcon />,
      onClick: (action) => setActionType(action.type),
      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_UPDATE_POSITION_START_DATE'],
    },
    {
      label: 'Change Status',
      type: 'change-status',
      icon: <PublishedWithChangesIcon />,
      onClick: (action) => setActionType(action.type),
      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_UPDATE_POSITION_STATUS'],
    },
  ];

  const filteredActionItems = type === "bulk"
  ? actionItems.filter((action) => {
    if ( positionStatus === 'Draft' && disablePublish &&
      (action.type === 'approve' || action.type === 'modify' || action.type === 'Cancelled' || action.type === 'map-cr-policy'))
      return true;
    if (positionStatus !== 'Draft' && action.type === 'resync')
      return true;
    if ((['approve', 'Open Pending Intake', 'Open Active Sourcing'].indexOf(positionStatus) >= 0) && disablePublish &&
      (action.type === 'published' || action.type === 'map-cr-policy'))
      return true;
    if ((['approve', 'Open Pending Intake', 'Open Active Sourcing'].indexOf(positionStatus) >= 0) && !disablePublish &&
       action.type === 'map-cr-policy')
      return true;
    if (positionStatus === 'On Hold' && action.type === 'map-cr-policy')
      return true;
    if (action.type === 'create-cr' && !disableCR &&
      (['approve', 'Open Pending Intake', 'Open Active Sourcing'].indexOf(positionStatus) >= 0))
      return true;
    if (action.type === 'change-status')
      return true;
  })
  : actionItems.filter((action) => {
    if (row['status'] === 'Draft' && row['publicationStatus'] === 'Unpublished' &&
        (action.type === 'approve' || action.type === 'modify' || action.type === 'reject'))
          return true;
    if ((row['status'] !== 'Draft') && user.isIntegratedWithCanvas && action.type === 'resync') 
          return true;
    if ((row['status'] !== 'Filled') && (action.type === 'map-cr-policy'))
          return true;
    if (action.type === 'create-cr' && row['changeRequestPolicy'] && (['Draft', 'Filled'].indexOf(row['status']) < 0))
          return true;
    if (action.type === 'change-status' && ['Open Pending Intake','Open Active Sourcing','On Hold', 'Cancelled'].indexOf(row['status']) >= 0)
          return true;
    if (action.type === 'change-status' && 
      (['Draft', 'On Hold - HM Delay', 'Offer Released', 'Offer Accepted', 'Offer Negotiation'].indexOf(row['status']) >= 0 && 
      !user.isIntegratedWithCanvas))
          return true;
    if (action.type === 'update-start-date' && ['Open Pending Intake','Open Active Sourcing','On Hold'].indexOf(row['status']) >= 0)
          return true;
    return false;
  })

  return <Component style={actionType === 'create-cr' && { dialog: {maxWidth: "md"}}} label={label} actions={filteredActionItems} dialog={dialogContent}/>
};